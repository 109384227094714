import { Model } from '@vuex-orm/core';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import { isArray } from 'lodash';
import {
  ApiORMBatchMethodConfigContract,
  ApiORMBatchMethodsContract,
} from '@/core/bridge/orm/api/contracts/ApiORMBatchContract';
import axios, { AxiosRequestConfig, Method } from 'axios';
import ORMModel from '@/core/bridge/orm/ORMModel';
import ApiQueryBuilder from '@/core/api/ApiQueryBuilder';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

declare type InstanceOf<T> = T extends new (...args: any[]) => infer R ? R : any;
declare type RequestType = 'create' | 'update';

export default class ApiORMBatchService extends ApiQueryBuilder {
  /**
   * Models collection for batch operations
   */
  protected models: Array<InstanceOf<InstanceOf<Model>>> = [];

  /**
   * Endpoint url
   */
  protected readonly url!: string;

  /**
   * Methods configuration
   */
  protected httpMethods: ApiORMBatchMethodsContract = {
    create: {
      url: '',
      method: 'post',
    },
    update: {
      url: '',
      method: 'patch',
    },
  };

  /**
   * Set base endpoint url
   * @param url
   */
  constructor(url: string) {
    super();

    this.url = url;
  }

  /**
   * Overwrite default methods map
   * @param map
   */
  public methods(map: ApiORMBatchMethodsContract): this {
    this.httpMethods = {
      ...this.httpMethods,
      ...map,
    };
    return this;
  }

  /**
   *
   * @param collection
   */
  public collection(collection: ORMCollection | Array<InstanceOf<InstanceOf<Model>>>): this {
    if (isArray(collection)) {
      this.models = collection;
    } else if (collection instanceof ORMCollection) {
      this.models = collection.items;
    }
    return this;
  }

  /**
   * Send create batch request
   */
  public async create(): Promise<any> {
    return await this.request('create', this.models);
  }

  /**
   * Send update batch request
   */
  public async update(): Promise<any> {
    return await this.request('update', this.models);
  }

  /**
   * Sync models by sending separate POST and PATCH requests
   */
  public async sync(): Promise<any> {
    if (!this.models.length) {
      return;
    }
    const postList: ORMModel[] = [];
    const patchList: ORMModel[] = [];
    this.models.forEach((model: InstanceOf<ORMModel>) => {
      if (model.id && model.id > 0) {
        patchList.push(model);
      } else {
        postList.push(model);
      }
    });

    if (patchList.length) {
      await this.request('update', patchList);
    }
    if (postList.length) {
      // return post response with new ids
      return await this.request('create', postList);
    }
  }

  /**
   * Send request and return response by type
   * @param type
   * @param collection
   */
  protected async request(type: RequestType, collection: ORMModel[]) {
    const data: IndexableInterface[] = [];
    collection.forEach((model: ORMModel) => {
      if (model.hasDataDifferences()) {
        data.push(model.dataDifferences());
      }
    });

    if (!data.length) {
      // there are no differences at all models
      return;
    }

    let parsedData: any = [];
    if (type === 'create') {
      parsedData = this.getSchema().prepareBatchCreateData(data);
    } else if (type === 'update') {
      parsedData = this.getSchema().prepareBatchUpdateData(data);
    }

    const axiosInstance = axios.create();

    const url: string = this.prepareUrl(type, this.getParams());
    const config: AxiosRequestConfig = {
      ...this.axiosRequestConfig,
      url,
      data: parsedData,
      method: this.typeMethod(type),
    };

    await this.prepareAxiosResponse(axiosInstance, {
      url: config.url!,
      method: config.method!,
    });

    return axiosInstance.request(config);
  }

  /**
   * Return url for request type including params
   * @param type
   * @param config
   */
  protected prepareUrl(type: RequestType, config: IndexableInterface = {}): string {
    const method: ApiORMBatchMethodConfigContract | undefined = this.httpMethods[type];
    if (!method) {
      return '';
    }

    return super.prepareUrl(`${this.url}${method.url}`, config);
  }

  /**
   * Return request method by type
   * @param type
   */
  private typeMethod(type: RequestType): Method {
    const config: ApiORMBatchMethodConfigContract | undefined = this.httpMethods[type];
    return config && config.method ? config.method : 'post';
  }
}
